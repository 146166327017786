<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <h1>Despesas</h1>
    <ExpenditureForm
      :inApprove="false"
      :show="showForm"
      :expenditureId="expenditureId"
      :userId="userId"
      v-on:cancel="closeForm"
      v-on:expenditureSaved="expenditureSaved"
    />
    <DeleteExpenditureConfirm
      :show="deleteExpenditureShow"
      :expenditureId="expenditureId"
      v-on:cancel="cancelDelete"
      v-on:expenditureDeleted="expenditureDeleted"
    />
    <div class="p-d-flex p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :value="expenditureList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :sortOrder="-1"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem despesas. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <span v-if="myUsers && myUsers.length > 1">
              <label for="myUsersList" class="p-mr-2 p-my-auto"
                >Visualizar</label
              >
              <Dropdown
                name="myUsersList"
                class="form-control p-mr-2"
                v-model="userId"
                :inputId="'myUsersList'"
                :options="myUsers"
                :optionLabel="'username'"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
                @input="userChanged"
              />
            </span>
            <label for="year-selector" class="p-mr-1 p-my-auto">Ano</label>
            <InputNumber
              id="year-selector"
              class="p-ml-2 p-mr-2"
              :value="selectedYear"
              showButtons
              buttonLayout="stacked"
              inputStyle="width:65px"
              :step="1"
              :min="currentYear - 10"
              :max="currentYear"
              :useGrouping="false"
              @input="yearChanged"
            />

            <Button
              id="btn-add-new"
              class="p-button-success"
              icon="pi pi-plus"
              v-tooltip="'Adicionar Despesa'"
              @click="addNew()"
            />
          </div>
        </template>
        <Column
          field="user"
          header="Funcionário"
          sortable
          filterMatchMode="contains"
          v-if="myUsers && myUsers.length > 1"
        >
        </Column>
        <Column
          field="date"
          header="Data"
          filterField="date"
          dataType="date"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
          <template #body="slotProps">
            <span class="p-column-title">Data:</span>
            {{ slotProps.data.day }}
          </template>
        </Column>
        <Column
          field="businessName"
          header="Negócio"
          sortable
          filterField="businessName"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="installationName"
          header="Instalação"
          sortable
          filterField="installationName"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="patName" header="Pat" filterField="patName" sortable>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="value"
          header="Valor"
          filterField="value"
          dataType="numeric"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            <span class="p-column-title">Valor:</span>
            {{ slotProps.data.value.toFixed(2) }} €
          </template>
        </Column>
        <Column
          field="approved"
          header="Aprovada"
          filterField="approved"
          dataType="boolean"
          sortable
        >
          <template #body="slotProps">
            <span class="p-column-title">Aprovada:</span>
            {{ slotProps.data.approved == true ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="rejectReason"
          header="Razão Rejeição"
          sortable
          filterField="rejectReason"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            {{ slotProps.data.rejectReason }}
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '7.5%', 'max-width': '7.5%' }"
          :filterHeaderStyle="{ 'min-width': '7.5%', 'max-width': '7.5%' }"
          :bodyStyle="{ 'min-width': '7.5%', 'max-width': '7.5%' }"
        >
          <template #body="slotProps">
            <Button
              v-if="!slotProps.data.approved"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-text p-button-success p-mr-2"
              @click="editExpenditure(slotProps.data)"
            />
            <Button
              v-if="!slotProps.data.approved"
              icon="pi pi-trash"
              class="p-button-rounded p-button-text p-button-danger"
              @click="deleteExpenditure(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.approved"
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-success p-button-outlined p-mr-2"
              @click="editExpenditure(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import expenditureService from "../services/expenditure.service";
import userService from "../services/user.service";
import { FilterMatchMode } from "primevue/api/";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import ExpenditureForm from "../components/expenditure/ExpenditureForm.vue";
import DeleteExpenditureConfirm from "../components/expenditure/DeleteExpenditure.vue";

export default {
  name: "Expenditure",
  components: {
    W4Mstreak,
    ExpenditureForm,
    DeleteExpenditureConfirm,
  },
  data() {
    return {
      loading: true,
      filters: {
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        businessName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        patName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rejectReason: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: { value: null, matchMode: FilterMatchMode.EQUALS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      selectedYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      streakStatus: [],
      loggedUser: null,
      userId: null,
      myUsers: [],
      allExpenditures: [],
      expenditureList: [],
      approved: [],
      toApproved: [],
      reject: [],
      activeStatus: null,
      showForm: false,
      deleteExpenditureShow: false,
      expenditureId: null,
    };
  },
  async created() {
    this.loading = true;
    this.activeStatus = this.$route.params.status
      ? this.$route.params.status
      : null;
    this.loggedUser = this.$store.state.auth.user;
    this.userId = this.loggedUser.id;
    if (this.can("viewExpendituresOtherUsers")) {
      this.myUsers = await userService.getMyUsers();
    }
    await this.getExpenditure();
  },
  methods: {
    yearChanged(year) {
      if (this.selectedYear == year) {
        return;
      }
      this.selectedYear = year;
      return this.getExpenditure();
    },
    getExpenditure() {
      this.loading = true;
      return expenditureService
        .getUserExpenditureByYear(this.userId, this.selectedYear)
        .then((response) => {
          response = response.map((v) => ({ ...v, date: new Date(v.day) }));
          this.loading = false;
          this.allExpenditures = response;
          this.approved = this.allExpenditures.filter(
            (expenditure) => expenditure.approved == true
          );
          this.toApproved = this.allExpenditures.filter(
            (expenditure) =>
              expenditure.approved == false && expenditure.rejectReason == ""
          );
          this.reject = this.allExpenditures.filter(
            (expenditure) =>
              expenditure.approved == false && expenditure.rejectReason != ""
          );

          if (this.activeStatus == "Rejeitadas") {
            this.expenditureList = this.reject;
          } else {
            this.expenditureList = this.toApproved;
          }
          return this.initStreakStatus();
        });
    },
    userChanged(select) {
      this.userId = select;
      this.getExpenditure();
    },
    initStreakStatus() {
      let countExpenditures = this.getExpenditureStatus();
      this.streakStatus = [
        {
          backgroudColor: "#38bbea",
          textColor: "#000",
          title: "Total",
          counter: countExpenditures.total,
          active: false,
        },
        {
          backgroudColor: "#00ff87",
          textColor: "#000",
          title: "Aprovadas",
          counter: countExpenditures.approved,
          active: false,
        },
        {
          backgroudColor: "#E1C800",
          textColor: "#000",
          title: "Por Aprovar",
          counter: countExpenditures.toApproved,
          active: this.activeStatus != "Rejeitadas",
        },
        {
          backgroudColor: "#ff2626",
          textColor: "#000",
          title: "Rejeitadas",
          counter: countExpenditures.reject,
          active: this.activeStatus == "Rejeitadas",
        },
      ];
    },
    getExpenditureStatus() {
      return {
        total: this.allExpenditures.length,
        approved: this.approved.length,
        toApproved: this.toApproved.length,
        reject: this.reject.length,
      };
    },
    streakSelectedChange(selected) {
      this.activeStatus = selected.currentState;
      this.clearFilters();
      switch (selected.currentState) {
        case "Total":
          this.expenditureList = this.allExpenditures;
          break;
        case "Aprovadas":
          this.expenditureList = this.approved;
          break;
        case "Por Aprovar":
          this.expenditureList = this.toApproved;
          break;
        case "Rejeitadas":
          this.expenditureList = this.reject;
          break;
      }
    },
    clearFilters() {
      this.filters = {
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        businessName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationName: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        patName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rejectReason: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: { value: null, matchMode: FilterMatchMode.EQUALS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    deleteExpenditure(expenditure) {
      this.expenditureId = expenditure.id;
      return (this.deleteExpenditureShow = true);
    },
    cancelDelete() {
      this.expenditureId = null;
      return (this.deleteExpenditureShow = false);
    },
    expenditureDeleted() {
      const areYou = (aux) => {
        return aux.id == this.expenditureId;
      };

      let index = this.expenditureList.findIndex(areYou);
      this.expenditureList.splice(index, 1);

      let index2 = this.allExpenditures.findIndex(areYou);
      this.allExpenditures.splice(index2, 1);

      if (this.activeStatus == "Por Aprovar") {
        let index3 = this.toApproved.findIndex(areYou);
        this.toApproved.splice(index3, 1);
      } else if (this.activeStatus == "Rejeitadas") {
        let index3 = this.reject.findIndex(areYou);
        this.reject.splice(index3, 1);
      }
      this.initStreakStatus();
      return this.cancelDelete();
    },
    closeForm() {
      this.expenditureId = null;
      return (this.showForm = false);
    },
    addNew() {
      this.expenditureId = null;
      return (this.showForm = true);
    },
    editExpenditure(expenditure) {
      this.expenditureId = expenditure.id;
      return (this.showForm = true);
    },
    expenditureSaved(info) {
      const areYou = (aux) => {
        return aux.id == this.expenditureId;
      };
      if (this.expenditureId == null) {
        this.toApproved.push(info.expenditure);
        this.allExpenditures.push(info.expenditure);
        if (this.activeStatus == "Por Aprovar") {
          this.expenditureList.push(info.expenditure);
        }
      } else {
        let index = this.expenditureList.findIndex(areYou);
        this.expenditureList[index] = info.expenditure;
        let index2 = this.allExpenditures.findIndex(areYou);
        this.allExpenditures[index2] = info.expenditure;
        if (this.activeStatus == "Por Aprovar") {
          let index = this.toApproved.findIndex(areYou);
          this.toApproved[index] = info.expenditure;
        } else if (this.activeStatus == "Rejeitadas") {
          let index = this.reject.findIndex(areYou);
          this.reject[index] = info.expenditure;
        }
      }
      this.initStreakStatus();
      return this.closeForm();
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.pdf-viewer {
  text-align: center;
}
</style>
