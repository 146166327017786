<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogRemoveExpenditure" />
  </div>
</template>
<script>
import expenditureService from "../../services/expenditure.service";
export default {
  name: "DeleteExpenditure",
  props: ["show", "expenditureId"],
  watch: {
    show() {
      if (this.show === true) {
        this.removeExpenditure();
      }
    },
  },
  methods: {
    removeExpenditure() {
      let title = `Remover Despesa`;
      let message = `Tem a certeza que pretende remover a despesa?`;
      this.$confirm.require({
        key: "dialogRemoveExpenditure",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.deleteExpenditure();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    deleteExpenditure() {
      expenditureService
        .deleteExpenditure(this.expenditureId)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao eliminar a despesa",
              life: 3000,
            });
          }

          this.$toast.add({
            severity: "success",
            summary: "Despesa eliminada",
            detail: "A despesa foi eliminada com sucesso",
            life: 3000,
          });

          return this.$emit("expenditureDeleted", {
            status: "ok",
          });
        });
    },
  },
};
</script>
